export type Result = {
  type: "Success" | "Failure";
  source: any;
};

export const wrapSuccess = <T = any>(source: T): Result => ({
  type: "Success",
  source
});

export const wrapFailure = <T = any>(source: T): Result => ({
  type: "Failure",
  source
});
