import { Action } from "@/store/actions/action_creator";

export default (state: string | null = null, action: Action): string | null => {
  switch (action.type) {
    case "AUTH#LOGIN":
      return action.payload.idToken;
    case "AUTH#LOGOUT":
      return null;
  }

  return state;
};
