import React from "react";
import Styled, { keyframes } from "styled-components";
import { AiOutlineLoading3Quarters as RawIcon } from "react-icons/ai";

export default ({ show }: { show: boolean }) => (
  <Root show={show}>
    <Icon />
  </Root>
);

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const size = "2rem";

const Root = Styled.div<{ show: boolean }>`
  height: ${size};
  margin: 1rem 0;

  ${({ show }) =>
    !show &&
    `
    display: none;
  `}
`;

const Icon = Styled(RawIcon)`
  display: inline-block;
  animation: ${rotate} 0.75s ease infinite;
  font-size: ${size};
  line-height: ${size}
  height: ${size}
  width: ${size}
  color: #000;
`;
