import Styled, { css } from "styled-components";
import { color } from "@/values";

export const buttonStyle = css`
  display: inline-block;
  font-size: 0.9rem;
  background: rgba(255, 255, 255, 0);
  outline: none;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  color: #fff;
  background: ${color.main};
  border-radius: 3px;
  transition: background 0.25s ease, color 0.25s ease;
`;

export const borderButtonStyle = css`
  ${buttonStyle}
  color: ${color.main};
  background: none;
  border: 1px solid ${color.main};

  &:hover {
    color: #fff;
    background: ${color.main};
  }
`;

export const roundedButtonStyle = css`
  ${buttonStyle}
  border-radius: 100px;
  padding: 0.4rem 1.2rem;
`;

export const roundedBorderButtonStyle = css`
  ${borderButtonStyle}
  ${roundedButtonStyle}
  background: none;
  color: ${color.main};
`;

export default Styled.button`
  ${buttonStyle}
`;

export const BorderButton = Styled.button`
  ${borderButtonStyle}
`;

export const RoundedButton = Styled.button`
  ${roundedButtonStyle}
`;

export const RoundedBorderButton = Styled.button`
  ${roundedBorderButtonStyle}
`;
