import React from "react";
import Styled from "styled-components";

export default ({ children }: { children: React.ReactNode }) => (
  <Root>{children}</Root>
);

const Root = Styled.h1`
  font-size: 2rem;
  font-weight: bolder;
  padding: 0;
  line-height: 1em;
  margin: 0 0 2rem;
`;
